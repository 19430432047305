<template>
  <v-card>
    <v-tabs v-model="tab" height="80" centered>
      <v-tabs-slider></v-tabs-slider>
      
      <v-tab v-for="psychologist in psychologistsList" :key="psychologist.id">
        <v-avatar class="mr-2" color="primary" size="50" :key="psychologist.id">
          <img :src="psychologist.photo" alt="" />
        </v-avatar>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="psychologist in psychologistsList"
        :key="psychologist.id"
      >
        <v-row class="d-flex flex-xs-column pa-4">
          <v-col sm="3" md="3" class="d-flex justify-center align-start">
            <div class="d-flex flex-column align-center justify-center">
              <v-avatar color="primary" size="150">
                <img :src="psychologist.photo" alt="" />
              </v-avatar>
              <span class="mt-5 font-gotham"
                >{{ psychologist.name }} {{ psychologist.surname }}</span
              >
              <span class="font-gotham"
                >{{
                  $vuetify.lang.t(
                    "$vuetify.main.patientReg.stepThree.workExperience"
                  )
                }}
                {{ psychologist.experience_ages }}
                {{
                  setConsultingExperienceText(psychologist.experience_ages)
                }}</span
              >
            </div>
          </v-col>
          <v-col sm="5" md="8" class="d-flex flex-column">
            <span class="title d-xs-none font-gotham"
              >{{ psychologist.name }} {{ psychologist.surname }}</span
            >
            <span class="pt-2 d-xs-none font-gotham"
              >{{
                $vuetify.lang.t(
                  "$vuetify.main.patientReg.stepThree.workExperience"
                )
              }}
              {{ psychologist.experience_ages }}
              {{
                setConsultingExperienceText(psychologist.experience_ages)
              }}</span
            >

            <div class="d-flex align-md-center pt-5 flex-xs-column">
              <div class="d-flex justify-space-between align-center">
                <v-icon large color="primary"> mdi-progress-check </v-icon>
                <span class="pl-3"
                  >{{
                    $vuetify.lang.t(
                      "$vuetify.main.patientReg.stepThree.worksWith"
                    )
                  }}
                  {{ psychologist.total_answers_in_common }}
                  {{
                    $vuetify.lang.t("$vuetify.main.patientReg.stepThree.outOf")
                  }}
                  <!--                  {{ totalUserAnswers }}-->
                  {{ totalUserAnswers }}
                  {{
                    $vuetify.lang.t("$vuetify.main.patientReg.stepThree.topics")
                  }}</span
                >
              </div>
              <div class="d-flex justify-space-between ml-5 align-center">
                <v-icon large color="primary"> mdi-calendar </v-icon>
                <span class="pl-3">
                  {{ $vuetify.lang.t("$vuetify.main.patientReg.stepThree.soon")
                  }}<v-spacer></v-spacer
                  >{{ psychologist.nearest_time_slot }}</span
                >
              </div>
            </div>

            <v-btn class="mt-8" max-width="250px" depressed color="primary">{{
              $vuetify.lang.t(
                "$vuetify.main.patientReg.stepThree.selectSessionTime"
              )
            }}</v-btn>

            <v-expansion-panels focusable class="mt-10">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.patientReg.stepThree.aboutSpecialist"
                    )
                  }}
                  <template v-slot:actions>
                    <v-icon color="primary">mdi-card-account-details</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span>
                    <p class="mt-5">
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.patientReg.stepThree.canContactMe"
                        )
                      }}
                    </p>
                    <p>{{ psychologist.about_info }}</p>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels focusable class="mt-10">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.patientReg.stepThree.education"
                    )
                  }}
                  <template v-slot:actions>
                    <v-icon color="primary"> mdi-school </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="mt-5">
                    {{ psychologist.education_year_of_ending }}
                    {{ psychologist.education_university }},
                    {{ psychologist.education_faculty }}.
                    {{ psychologist.education_speciality }}.
                    {{ psychologist.education_degree }}.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <p class="pt-6 font-gotham">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.patientReg.stepThree.therapyMethod"
                )
              }}
            </p>
            <span>
              <v-chip class="ma-2" color="primary" label text-color="white">
                <v-icon left> mdi-label </v-icon>
                {{ psychologist.main_treatment_method }}
              </v-chip>
            </span>

            <p
              class="font-gotham mt-7"
              v-if="psychologist.common_answers.length > 0"
            >
              {{
                $vuetify.lang.t("$vuetify.main.patientReg.stepThree.workTopics")
              }}
            </p>
            <div>
              <v-chip
                v-for="common_answers in psychologist.common_answers"
                :key="common_answers"
                class="ma-2"
                color="primary"
              >
                {{ common_answers }}
              </v-chip>
            </div>

            <p class="mt-7 font-gotham">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.patientReg.stepThree.dateAndTime"
                )
              }}
            </p>
            <div class="div-border mb-4">
              <div>
                <v-icon large color="primary"> mdi-progress-check </v-icon>
                <span class="pl-3">{{
                  $vuetify.lang.t("$vuetify.main.patientReg.stepThree.canShift")
                }}</span>
              </div>
              <div class="mt-3">
                <v-icon large color="primary"> mdi-progress-check </v-icon>
                <span class="pl-3"
                  >{{
                    $vuetify.lang.t(
                      "$vuetify.main.patientReg.stepThree.price",
                      price
                    )
                  }}
                  &#8372;</span
                >
              </div>
            </div>

            <v-row>
              <v-col class="d-flex flex-wrap pl-xs-0">
                <div class="d-flex flex-column">
                  <v-row
                    v-for="(timeSlot, index) in psychologist.time_slots"
                    :key="index"
                  >
                    <v-col cols="12" md="12">
                      <span v-if="timeSlot.slots.length > 0">{{
                        timeSlot.date.translated
                      }}</span>
                      <div class="btn__wrapper">
                        <v-btn
                          v-for="(slot, i) in timeSlot.slots"
                          :key="slot.id"
                          :disabled="slot.is_booked || !slot.available"
                          @click="
                            selectTimeSlot(
                              { slot, slotId: slot.id, date: timeSlot.date },
                              psychologist.id,
                              psychologist.name,
                              psychologist.surname,
                              psychologist.photo,
                              i,
                              $event
                            )
                          "
                          class="btn-disable btn-width"
                          outlined
                        >
                          {{ slot.time }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  name: "stepThree",
  data() {
    return {
      tab: null,
      el1: 1,
      activeSlot: null,
      selectedSlot: null,
      value: true,
      prevSlot: null,
      consultingExperienceYearsText: "",
      profile_id: null,
      calendar_id: null,
      psychologistName: null,
      psychologistSurname: null,
      psychologistPhoto: null,
      price: "",

      // avatarUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
    };
  },
  created() {
    this.$eventHub.$on(
      "patient-step-three-form_validate-form",
      this.validate(this.calendar_id)
    );
    this.price = process.env.VUE_APP_SESSION_PRICE;
  },
  beforeDestroy() {
    this.$eventHub.$off("patient-step-three-form_validate-form");
  },
  methods: {
    selectTimeSlot(
      slot,
      psychologistId,
      psychologistName,
      psychologistSurname,
      psychologistPhoto,
      i,
      event
    ) {
      this.profile_id = psychologistId;
      this.calendar_id = slot.slotId;
      this.psychologistName = psychologistName;
      this.psychologistSurname = psychologistSurname;
      this.psychologistPhoto = psychologistPhoto;

      this.selectedSlot = slot;
      this.activeSlot = i;

      if (this.prevSlot) {
        this.prevSlot.classList.remove("active");
      }

      if (event.target.tagName === "SPAN") {
        this.prevSlot = event.target.parentElement;
        event.target.parentElement.classList.toggle("active");
      } else {
        this.prevSlot = event.target;
        event.target.classList.toggle("active");
      }
      this.$store.dispatch(
        "user/setPatientQuestionnaireCalendarId",
        this.calendar_id
      );
      this.$store.dispatch(
        "user/setPatientQuestionnaireProfileId",
        this.profile_id
      );
      this.$store.dispatch(
        "user/setPatientQuestionnairePsychName",
        this.psychologistName
      );
      this.$store.dispatch(
        "user/setPatientQuestionnairePsychSurname",
        this.psychologistSurname
      );
      this.$store.dispatch(
        "user/setPatientQuestionnaireSelectedSlot",
        this.selectedSlot
      );
      this.$store.dispatch(
        "user/setPatientQuestionnairePsychPhoto",
        this.psychologistPhoto
      );

      this.$emit("userSelect", this.selectedSlot);
    },
    validate(calendar_id) {
      if (calendar_id) {
        this.$eventHub.$emit("switch-patient-registration-tab", 4);
      }
    },
    setConsultingExperienceText(consultingExperience) {
      if (
        consultingExperience === 11 ||
        consultingExperience === 12 ||
        consultingExperience === 13 ||
        consultingExperience === 14
      ) {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years"
        );
      } else if (consultingExperience % 10 === 1) {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.year"
        );
      } else if (
        consultingExperience % 10 === 2 ||
        consultingExperience % 10 === 3 ||
        consultingExperience % 10 === 4
      ) {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years3"
        );
      } else {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years"
        );
      }
    },
  },
  computed: {
    psychologistsList() {
      return this.$store.getters["user/getPsychologistsList"].data;
    },
    totalUserAnswers() {
      return this.$store.getters["user/getPsychologistsList"]
        .total_user_answers;
    },
  },
};
</script>

<style scoped>
.sticky-slide-top-bottom {
  position: fixed;
}

@media screen and (max-width: 600px) {
  .sticky-slide-top-bottom {
    position: relative;
  }

  .flex-xs-column {
    flex-direction: column;
  }

  .d-xs-none {
    display: none !important;
  }

  .pl-xs-0 {
    padding-left: 0;
  }
}
@media screen and (max-width: 1460px) {
  .font-gotham {
    font-size: 15px;
  }
}

.btn__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0 0 10px;
}

.btn-width {
  width: 84px;
}

.width-200 {
  max-width: 280px;
}

.div-border {
  max-width: 350px;
  border: 1px solid #000000;
  border-radius: 15px;
  padding: 15px;
}

.btn-disable {
  color: #59b6b9;
}

.active {
  color: #ffffff !important;
  background-color: #59b6b9 !important;
}
</style>
